<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col lg="8" md="6" class="d-none d-lg-flex p-0">
        <SplashScreen />
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1"> MyFlight Connect </b-card-title>
          <b-card-text class="mt-2">
            <div>
              <p>{{ $t('register.validation_text') }}</p>
              <b-overlay :show="validationLoading">
                <b-link @click="resendMail">
                  <span>{{ $t('register.email_not_received') }}</span>
                </b-link>
              </b-overlay>
            </div>
          </b-card-text>
          <b-card-text class="text-center mt-2">
            <div>
              <span>{{ $t('login.have_question') }}</span>
              <b-link :to="{ name: 'contact' }">
                <span> {{ $t('login.contact_us') }}</span>
              </b-link>
            </div>
          </b-card-text>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SplashScreen from '@/views/base/SplashScreen.vue'
import { patchRegisterNewValidationMailRequest } from '@/request/authApi/requests/apiRequests'

export default {
  name: 'RegisterValidation',
  components: {
    SplashScreen,
  },

  data() {
    return {
      validationLoading: false,
    }
  },

  computed: {
    ...mapState('appConfig', ['layout']),
  },
  methods: {
    resendMail() {
      this.validationLoading = true
      patchRegisterNewValidationMailRequest(this.$route.params.id, { redirectUrl: `${window.location.origin}/register-validate` }).finally(() => {
        this.validationLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
